@import url("https://use.typekit.net/rlf4gcj.css");
$red: #e4001c;
$green: #24862f;
$bg: #000;
$sigFont: nobel, sans-serif;
$special: natura, serif;

.mgrill.site-content {
  background-color: #ffffff;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='96' viewBox='0 0 60 96'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23f2f2f2' fill-opacity='0.4'%3E%3Cpath d='M36 10a6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-12 0 6 6 0 0 0-6-6 6 6 0 0 1-6-6V10a6 6 0 1 1 12 0 6 6 0 0 0 12 0zm24 78a6 6 0 0 1-6-6 6 6 0 0 0-6-6 6 6 0 0 1-6-6V58a6 6 0 1 1 12 0 6 6 0 0 0 6 6v24zM0 88V64a6 6 0 0 0 6-6 6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-6 6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
}
.taq {
  font-family: $sigFont;
  .cart.minimized {
    // background: url(http://mexcocinacafe.com/wp-content/uploads/2014/05/wood.jpg) no-repeat;
    padding: 0.4em 0.5em 0.1em 0.5em;
    background-color: #ffffff;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='60' height='96' viewBox='0 0 60 96'%3E%3Cg fill-rule='evenodd'%3E%3Cg fill='%23f2f2f2' fill-opacity='0.4'%3E%3Cpath d='M36 10a6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-12 0 6 6 0 0 0-6-6 6 6 0 0 1-6-6V10a6 6 0 1 1 12 0 6 6 0 0 0 12 0zm24 78a6 6 0 0 1-6-6 6 6 0 0 0-6-6 6 6 0 0 1-6-6V58a6 6 0 1 1 12 0 6 6 0 0 0 6 6v24zM0 88V64a6 6 0 0 0 6-6 6 6 0 0 1 12 0v12a6 6 0 0 1-6 6 6 6 0 0 0-6 6 6 6 0 0 1-6 6z'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    // background-size: cover;
    border-bottom: none;

  }
  a.checkout-button.button {
    // font-family: 'Bungee Inline', sans-serif;
    background: $red;
    // font-weight: 400;
    box-shadow: none;
    // border: 2px solid #5a7e2a;
  }

  .category-name .text {
    position: relative;
    // text-transform: lowercase;
    font-family: $special;
    font-weight: 400;
    font-size: 2.5em;
    background: none;
    color: black;
    // border: 4px solid #5a7e2a;
    // border-radius: 1em;
    // color: whtie;
    // text-shadow: 0px 1px 0 black;

  }
  // .category-name .text::before {
  //   content: " ";
  //   display: inline-block;
  //
  //   background: url(https://afag.imgix.net/mediterradean-grill/mgrill-icon.png?w=100) no-repeat;
  //   background-size: contain;
  //   // width: 28px;
  //   // height: 32px;
  //   // margin-right: -0.1em;
  //   width: 50px;
  //   height: 50px;
  //   // margin-right: -0.1em;
  //   position: absolute;
  //   left: -0.8em;
  //   top: -0.2em;
  // }
  .food-name {
    // text-transform: lowercase;
    // text-transform: capitalize;
  }
  .section-header {
    text-align: center;
    font-weight: 600;
    padding: 0.5em 0.8em;
    h2 {
      font-weight: 800;
      text-transform: uppercase;
      color: black;
    }
  }
  .splash {
    z-index: 2;
    position: absolute;
    width: 100%;
    top: 1em;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding: 1em 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
    h2 {
      color: white;
    }
    .catch-phrase {
      font-size: 2em;
    }
    @media screen and (max-width: 650px) {
      font-size: 1em;
    }
  }
  .landing-page, .top-banner {
    position: relative;
    font-family: $sigFont;
    /* color: white; */
    /* text-shadow: 0px 0px 4px rgba(255,255,255,1); */
    padding: 1em 0;
    margin: 0;
    display: block;
    min-height: 200px;
    /* max-height: 50vh; */
    // background: url(https://image.shutterstock.com/z/stock-photo-chinese-food-on-dark-wooden-table-noodles-fried-rice-dumplings-stir-fry-chicken-dim-sum-466836857.jpg);
    background: url(https://afag.imgix.net/taqueria-el-tapatio/hero.jpg?w=1000);
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5em;
  }
  .landing-page .text {
    // font-family: Helvetica, sans-serif;
    color: white;
    background: rgba(0,0,0,0.3);
    padding: 0.5em 0.8em;
    text-align: center;
    h1 {
      font-weight: 600;
    }
    .location {
      .address {
        font-size: 0.6em;
      }
    }
  }


  .pages-nav {
    font-family: $sigFont;
    // text-transform: uppercase;
    font-weight: 600;
    font-size: 0.9em;
    // background: white;
    // color: black;
    ul {
      display: flex;
      flex-wrap: wrap;
    }
    @media screen and (max-width: 480px) {
      font-size: 4vw;
    }
  }
  $borderWidth: 4px;
  $paddingY: 0.35em;
  a.page-link {
    text-decoration: none;
    color: black;
    display: block;
    padding: $paddingY 0.3em;
    padding: $paddingY 0.3em calc(#{$paddingY} - #{$borderWidth}) 0.3em;
    // box-sizing: border-box;
    border-bottom: $borderWidth solid white;
    &:hover, &.active {
      border-bottom: $borderWidth solid black;
    }
  }

  a.order-online-link {
    // text-transform: uppercase;
    // font-family: 'Helvetica', sans-serif;
    font-size: 0.8em;
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: white;
    margin: 0.5em 0;
    padding: 0.4em 0.6em;
    border-radius: 0.2em;
    background: $red;
    position: relative;
    box-shadow: 1px 1px #770523, 3px 3px #770523, 5px 5px #770523, 7px 7px #770523;
    transition: all 0.12s ease 0s;
    &:hover {
      transform: translate(5px, 5px);
      box-shadow: none
    }
    &.disabled:hover {
      transform: none;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
    &.disabled {
      background: gray;
      box-shadow: 1px 1px #313131, 3px 3px #313131, 5px 5px #313131, 7px 7px #313131;
    }
  }
  .ordering-header-wrapper {
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,f_auto,w_1280/v1544211832/danstuff/Menu.jpg) no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 250px;
    color: white;
    font-size: 1.5em;
    font-weight: 900;
    .text {
      padding: 1em;
      background: rgba(0,0,0,0.5);
    }
  }
  .landing-page-wrapper {
    position: relative;
  }
  .landing-page {
    // height: 540px;
  }
  .fire-bg {
    height: 100%;
    background: url(https://d2abh92eaqfsaa.cloudfront.net/image/upload/c_scale,w_1000/v1551995999/danstuff/grill-gif.gif) no-repeat;
    background-size: cover;
    background-position: center;

  }
  .video-bg {
    // filter: brightness(0.5);
    position: relative;
    width: auto;
    min-width: 100%;
    height: auto;
    min-height: 100%;

    background-size: cover;
  }
  video {
    display: block;

  }
  .video-container {

    width: 100%;
    // height: 100%;
    height: 540px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
    // max-width: 960px;
    overflow: hidden;
    // position: absolute;
    top: 0;
    right: 0;
  }
  .fire-gif {
    height: 540px;
    @media screen and (max-width: 650px) {
      height: 400px;
    }
  }
}
